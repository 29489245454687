import React from 'react';
import styled from 'styled-components';
import { Title2, Title3 } from '../home/homeStyledComponents';
import { colors, mobileThresholdPixels } from '../styledComponents';
import axaLogo from '../../assets/axa.png';
import Declare from './Declare';
import Assurance1 from '../../assets/assurance-1.jpg';
import Assurance3 from '../../assets/assurance-3.jpg';
import Assurance4 from '../../assets/assurance-4.jpg';
import InsuranceTable from './InsuranceTable';

const Container = styled.div`
  margin: 0px 100px;
  padding: 0px 0px 60px;
  color: ${colors.navy};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
  }
`;

const Logo = styled.img`
  width: 150px;
`;

const P = styled.p`
  align-self: flex-start;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-left: 5px;
    margin-right: 5px;
    text-align: justify;
  }
`;

const Img = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;

const Exclusion = styled.ul`
  font-size: 13px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 10px;
  }
`;

const InsuranceTillisteBlock = () => (
  <Container>
    <Img src={Assurance1} alt="Retouche express Paris" />
    <Title2 noMargin>{"L'assurance Tilli"}</Title2>
    <Title3 margin>Réalise en toute sérénité tes missions</Title3>
    <Logo src={axaLogo} alt="Retoucherie ouverte le dimanche" />

    <Title3 margin>Une assurance pour toutes tes missions Tilli</Title3>
    <P>
      L’assurance Tilli te couvre pour tout dommages corporels, matériel et immatériel causés à une autre personne
      dans le cadre d’une mission. Par exemple : Un incendie ou un dégât des eaux se produit chez toi ?
      Un voleur te dérobe les vêtements qu’un client t’as confiés ?
      Pas d’inquiétudes, tu es couvert par AXA !
    </P>
    <P>
      Très concrètement, la responsabilité civile, ou « RC » pour les initiés, est la responsabilité que chacun
      de nous a envers ses tiers (i.e. : les autres personnes). Si je suis à l’origine d’un incident qui vient
      causer des dommages à une tierce personne, alors « ma responsabilité civile est engagée ».
    </P>
    <P>
      L’assurance Tilli vient donc couvrir les dommages corporels, matériels et immatériels causés à autrui
      dans le cadre d’une mission.
    </P>
    <P>
      Nous précisions que cette assurance vaut en complément ou à défaut de toute assurance que tu aurais déjà
      souscrite au préalable. Si tu es déjà assuré par ailleurs, ton autre assurance entrera en jeu.
    </P>
    <P>
      La qualité de vie et les conditions de travail de nos partenaires sont une priorité pour nous.
      Cette assurance t’est donc offerte par Tilli pour te permettre d’exercer ton activité en toute sérénité.
    </P>

    <Img src={Assurance3} alt="Cintrage de robe" />

    <Title3 margin>Tableau des montant des garanties et des franchises</Title3>
    <InsuranceTable />

    <Title3 margin>Quelles sont les exclusions ?</Title3>
    <Exclusion>
      <li><P>Les dommages subis par les biens que l’assuré détient en vertu d’un contrat de dépôt rémunéré ou qui
        lui sont remis en vue de la vente ou de la location ;</P></li>
      <li><P>Les dommages subis par les biens loués ou prêtés à titre onéreux à l’assuré ou qu’il détient en
        vertu d’un contrat de crédit-bail ou de location- vente ;</P></li>
      <li><P>Les dommages causés en cours de transport. Toutefois, si l’assuré n’est pas un transporteur
        professionnel, la garantie lui est acquise lorsqu’il effectue lui-même un transport accessoirement
        aux activités définies au contrat ;</P></li>
      <li><P>Les dommages subis par les espèces, les biens et objets de valeurs tels que titres, bijoux, pierreries,
        perles fines, objets en métaux précieux, pierres dures, statues, tableaux, collections, objets
        relevant du marché de l’art, fourrures ;</P></li>
      <li><P>Les objets essentiellement fragiles tels que verreries, porcelaines, terres cuites, plâtres,
        statues, céramiques, faïences, miroirs ;</P></li>
      <li><P>Les objets en mauvais état au moment du sinistre ; </P></li>
      <li><P>Le vol, la perte ou la disparition totale ou partielle des biens confiés. Toutefois,
        demeure garanti le vol de biens mobiliers  dans l’enceinte des établissements objets du contrat
        de prestation  causé par :
        <ul>
          <li><P>Les préposés de l’assuré au cours ou à l’occasion de leurs fonctions,</P></li>
          <li><P>des tiers lorsque la responsabilité de l’assuré est engagée par suite d’une négligence imputable
            à lui-même ou à ses préposés.</P></li>
        </ul>
      </P></li>

      <P>ainsi que les dommages immatériels qui en sont la conséquence.</P>
    </Exclusion>

    <Img src={Assurance4} alt="Retouche chemise haut t-shirt robe jupe" />

    <Declare />
  </Container>
);

export default InsuranceTillisteBlock;
